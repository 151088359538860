<template>
  <b-card>
  <div class="card-header trainer-header bg-primary bg-lighten-2 rounded shadow">
   <h2 class="room-name">
      <b>
        <feather-icon
          icon="HomeIcon"
          class="mr-25 home-icon mb-50"/>
          {{$t('Room')}}</b> : {{room_name}}</h2>
  </div>
    <b-form @submit.prevent>
  <template>
    <div class="row match-height">
              <div  v-for="(active,index) in activities" class="col-md-6 col-lg-4 m-0 p-1" style="padding:2px;" :key="index">
                <div class="card bg-primary bg-lighten-2 bg-hover shadow-effect-card" style="margin:2px;">
                  <div class="card-header bg-gradient-primary border-bottom border-2 rounded-top">
                    <h4 class="card-title activity-name text-white pb-0 my-0">{{active.activity_name}}</h4>
                  </div>
                  <div class="card-body border-top border-2 rounded-bottom">
                      <div class="mt-1" v-for="(activity_type,index_2) in active.activity_types" :key="index_2">
                          <input type="hidden" id="room_capacity_id" v-model="activity_type.room_capacity_id">
                          <input type="hidden" id="activity_detail_id"  v-model="activity_type.activity_detail_id">
                          <input type="hidden"  id="room_id"  v-model="activity_type.room_id">

                          <b-form-group :label="$t('Capacity')"
                               label-cols-md="12"
                               label-cols-lg="12"
                               label-cols-sm="12"
                               label-align-lg="left"
                               label-align-md="left"
                               label-align-sm="left"
                               label-class="activity-type-name"
                               class="m-0" label-for="h-activity-name">
                                <b-form-input id="capacity"
                                              :placeholder="$t('0')"
                                              class="col-md-12 capacity-input shadow-effect-input"
                                              v-model="activity_type.capacity"
                                              @change="changeValue"
                                              @blur="$v.activity_type.capacity.$touch()"
                                              type="number"
                                              >
                                </b-form-input>
                          </b-form-group>

                                <!-- <small v-if="!$v.active.$activity_type.capacity.required" class="form-text text-danger" >{{this.$t("This field is required")}}</small>
                                <small v-if="!$v.active.$activity_type.capacity.numeric" class="form-text text-danger" >{{this.$t("Sadece Rakamlardan Oluşmalı")}}</small>  -->
                      </div>
                  </div>
                </div>
              </div>
      </div>
</template>

     <template>

        <b-row>
        <!-- submit and reset -->
        <b-col offset-md="4" class="text-right">
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              :to="{name: 'studio_room_list'}"
          >
            {{ $t("Cancel") }}
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click="cretateActivity"
              class="ml-1"
          >
            {{ $t("Save") }}
          </b-button>
        </b-col>
      </b-row>
      </template>
    </b-form>

  </b-card>
</template>

<script>
import {
  BAlert,BCard,BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,vImage,BBadge
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import DataBase from "@/services/DataBase"
import vSelect from 'vue-select'
import VSwatches from 'vue-swatches'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import "vue-swatches/dist/vue-swatches.css";
import {required,numeric} from "vuelidate/lib/validators";
export default
{
  name: 'Create_Room_Capacity',
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    VSwatches,
    vImage,
    BBadge
  },
  directives: {
    Ripple,
  },
  data()
  {
    return {
         room_id :null,
         room_name:null,
         activities:[
            {
             activity_id:null,
             activity_name:null,
             activity_types:[
               {
                   room_capacity_id:null,
                   activity_detail_id:null,
                   room_id:null,
                   capacity:null,
                   activity_type_name:null

               }
              ]
            }

         ],

         result_activity_types:[],
         isValueChange: false,
         companyBranchId: ''


    }
  },
  validations:
  {
          activities:
            {
                 activity_types:

                {
                     capacity:{
                       required:required,
                       numeric:numeric
                 }
                }

            }
  },

  watch:{
    activites(val)
    {
        console.log("Veriler "+ val )
    },
  },
  methods:{

      onSubmit()
      {
          // let form = {
          // email : this.email,
          // password : this.password,
          // category : this.category,
          // hobbies : this.hobbies,
          //}
      },
        newActivity()
        {
        let activity = {
          activityDetailId: Math.random(1,100)*1000,
          activityName:"Test",
          activityCapacity:"10"
        }
        this.activities.push(activity)
      },

      // async  mockData()
      // {
      //   let activite_1={
      //       activity_id:"A11",
      //       activity_name:"COMBAT",
      //       activity_types:[
      //         {activity_type_id:"G99",activity_detail_id:"AD99" , activity_type_name:"GX",activity_capacity:"5"},
      //         {activity_type_id:"P88",activity_detail_id:"AD88" , activity_type_name:"PT",activity_capacity:"1"}]
      //   };
      //   this.activites.push(activite_1);
      //   //console.log(this.activites)
      //   //return activites;

      // },
      getActivityDetail()
      {
            DataBase.ActivityService.activity_detail_type_get_all_list(this.companyBranchId,this.room_id).then(
              (res) => {
                if (!res.is_success)
                return;

                  console.log("xactres.result => ",res.result);



                   res.result.forEach((activity, index) =>
                  {
                        activity.activity_types.forEach((activity_type,index)=>
                        {

                            activity_type.room_id= this.room_id;
                            //activity_types.push(activity_type);
                        });

                        this.activities= res.result;
                  });




                this.activities= res.result;

              //  console.log("xactivity_types => ",res.result);

              });
      },
      cretateActivity()
      {
            //Validation kontrol
            //  if (this.$v.$invalid)
            //   return;




        this.result_activity_types=[];// eski kayıtları sil

         /**Kayıt işleminde kullannılacak veri setlerini belirle.
           Örneğin RoomCapasite Güncelleme ve Kaydet işlenminde
          activity_name, activity_type_name,activity_type_id ye ihtiyaç yok */

        this.activities.forEach((activity, index) =>
         {
              activity.activity_types.forEach((activity_type,index)=>
              {
                  let newData={}
                newData.activity_detail_id=activity_type.activity_detail_id;
                newData.capacity=activity_type.capacity;
                newData.room_capacity_id=activity_type.room_capacity_id;
                newData.room_id=activity_type.room_id;

                this.result_activity_types.push(newData);
              });
          });



             this.$database.RoomService.update_or_create_room_capacity(this.companyBranchId,JSON.stringify(this.result_activity_types))
              .then(res=> {
            if (!res.is_success)
            {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t("Action failed!"),
                  text:res.result,
                  icon: "XIcon",
                  variant: "danger",
                },
              });
              return;
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("Action Successful"),
                icon: "EditIcon",
                text:res.result,
                variant: "success",
              },
            });
            this.isValueChange = false;
            activityTypes=[];
            this.$router.push({name:'activityList'});
          })
      },
      changeValue(){
        this.isValueChange = true;
      }
  },
  created()
  {
      this.companyBranchId = this.$route.params.studioID;
      this.room_id = this.$route.params.roomID;
      this.room_name=this.$route.params.roomName;
     // console.log("Datalar geliyor")
    this.getActivityDetail()
   // console.log("Datalar:"+(JSON.stringify(this.activites)));

    //Sayfa açıldığında ilk yapılacakların eklenecek olduğu ekran Biz bu sayfada activity tiplerini ekliyoruz
    //this.getData()
  },
  mounted(){
    console.log("activities");
    console.log(this.activities);
  },
  beforeRouteLeave(to,from,next){
    if(this.isValueChange){
        this.$swal({
        title: this.$t("Are you sure"),
        text: this.$t("Are you sure you want to exit without saving your changes?"),
        icon: 'warning',
        confirmButtonText: this.$t("Yes"),
        showCancelButton: true,
        cancelButtonText:  this.$t("No"),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          next();
        }
      })
    }else {
      next()
    }

  }
}
</script>

<!-- stillendirme -->
<style lang="scss" src="@/styles/scss/createRoomCapacity.scss"></style>
